<template>

  <div class="row" ref="templateForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <!--                //Name & URL-->
            <div class="row">
              <div class="col-md-6">
                <ValidationProvider
                  vid="name"
                  rules="required"
                  name="The Name"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="name"
                            name="name"
                            @keyup="generateSomeFields"
                            fou
                            v-model="formData.name">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider
                  vid="url"
                  rules="required"
                  name="The URL"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="URL"
                            name="url"
                            fou
                            v-model="formData.url">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
            <!--                //Price-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="price"
                  rules="required"
                  name="The Price"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="number"
                            label="Price"
                            name="price"
                            fou
                            :error="failed ? errors[0]: null"
                            v-model="formData.price">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="adult_portion_price"
                  rules="required"
                  name="The Adult portion price"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="number"
                            label="Adult portion price"
                            name="price"
                            fou
                            :error="failed ? errors[0]: null"
                            v-model="formData.adult_portion_price">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>

            <!--              //Toppings-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="toppings"
                  rules=""
                  name="The Toppings"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="toppings"
                    size="large"
                    filterable
                    clearable
                    multiple
                    placeholder="Toppings"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="'Toppings'"
                    :list="toppingList"
                    :listItemLabel="'name'"
                    :listItemValue="'id'"
                    v-model="formData.toppings">
                  </fg-select>
                </ValidationProvider>
              </div>
            </div>

            <!--              //Description-->
            <div class="row">
              <div class="col-md-12 com-sm-12 mb-2 mt-2">
                <div class="form-group">
                  <label>Description</label>
                  <editor
                    v-model="formData.description"
                    :api-key="editorKey"
                    :init='editorConfig'
                  />
                </div>

              </div>
            </div>

            <!--              //Image-->
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="form-group">
                  <label>Image</label>
                  <el-tooltip placement="right" v-if="false">
                    <div slot="content"></div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <prime-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="1"
                    :maxFileSize="2000000"
                    :isEditMode="editMode"
                    v-model="formData.image"
                  >
                  </prime-uploader>
                </div>
              </div>
            </div>

            <!--              //Ingredients-->
            <div class="row">
              <div class="col-md-12">
                <label>Ingredients</label>
                <div class="col-12">
                  <template class="row" v-for="(listItem,listItemIndex) in formData.ingredients">
                    <div class="row team_list_items">
                      <label>Item # {{ listItemIndex + 1 }}</label>
                      <!--                list item title-->
                      <div class="col-md-12">
                        <ValidationProvider
                          vid="en.ingredientstitle"
                          rules=""
                          name="The Title"
                          v-slot="{ passed, failed,errors }">
                          <fg-input type="text"
                                    :key="'title_'+listItemIndex"
                                    :error="failed ? errors[0]: null"
                                    :label="'Title '+(listItemIndex+1)"
                                    v-model="formData.ingredients[listItemIndex].title">
                          </fg-input>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-sm-1 align-self-center mt-3">
                      <l-button
                        @click="removeItem(formData.ingredients, listItemIndex)"
                        type="danger" size="sm">
                        <i class="fa fa-trash"></i>
                      </l-button>
                    </div>
                  </template>
                  <l-button
                    class="mt-3"
                    type="success"
                    @click="addNewItem(formData.ingredients, {title: ''})"
                    size="sm">
                    <i class="fa fa-plus"></i>
                  </l-button>
                </div>
              </div>
            </div>

            <!--              //IS ACTIVE-->
            <div class="form-group pt-4">
              <label>Is Active</label>&nbsp;
              <l-switch v-model="formData.is_active">
                <i class="fa fa-check" slot="on"></i>
                <i class="fa fa-times" slot="off"></i>
              </l-switch>
            </div>


          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/meals/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Option, Select, Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    PrimeUploader,
    ImageUploader,
    PrimeVideoUploader,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,

      formData: {
        name: "",
        url: "",
        price: "",
        adult_portion_price : "",
        description: "",
        toppings: [],
        ingredients: [],
        is_active: true,
        image: "",
      },

      toppingList: [],
    };
  },

  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    })
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.templateForm
    });

    let data = {}
    this.axios.post("meals/builder", data).then((response) => {
      this.toppingList = response.data.toppings;

      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Meal";
        this.getItem();
      } else {
        this.editMode = false;
        this.formTitle = "Add Meal";
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    })
  },


  methods: {
    getItem() {
      let data = {
        'id': this.id,
      };
      this.axios.post("meals/get", data).then((response) => {
        this.formData = response.data;
        this.formData.toppings = this.formData.toppings.map(topping => topping.id);
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Meal Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    generateSomeFields() {

      let newUrl = '';
      if (this.formData.name) {
        newUrl = this.formData.name.replace(/\s+/g, "-").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
        newUrl = newUrl.replace(/([-]+)/g, "-");
      }

      this.formData.url = newUrl;
    },

    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      if (this.editMode === true) {
        request = this.axios.put("meals/update/" + this.id, this.formData);
        successMessage = "Meal Updated Successfully";
      } else {
        request = this.axios.post("meals/create", this.formData);
        successMessage = "Meal Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/meals/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
          if (error.response.data.errors.image !== undefined){
            this.$notify({
              message: error.response.data.errors.image[0],
              timeout: 6000,
              type: 'danger'
            })
          }
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    addNewItem(listItems, initObject) {
      listItems.push(initObject);
    },
    removeItem(listItems, index) {
      listItems.splice(index, 1);
    },

  }
}
</script>
